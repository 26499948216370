.nav nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Protest reg;
  padding-inline: 10%;
  /* color: white; */

}
.nav nav img{
  width: 5%;
}
.nav nav ul {
  display: flex;
  list-style: none;
  gap: 2rem;
  font-weight: 900;
  font-size: 30px;
}
.nav nav ul a {
  text-decoration: none;
  color: inherit;
}
.hero{
  padding-inline: 10%;
}
.hero section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4%;
  /* height: 70vh; */
  /* color: white; */
  margin-bottom: 3rem;
  /* background-image: url(/public/images/Website.PNG);
  background-repeat: no-repeat;
  background-position: 10%;
  background-size: contain; */
}
.hero section main{
  width: 100%;
  display: flex;
  justify-content: center;
  /* position: relative; */
}
.hero section main img {
  width: 70%;
}
.hero .comment{
  width: 50%;
  object-fit: contain;
  transform: translateY(-40%);
}
/* Define bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px); /* Adjust bounce height and direction */
  }
}

/* Apply bouncing animation to the image */
.nomImage {
  animation: bounce 1s infinite; /* Adjust duration and timing */
}

.hero section article {
  /* border: 1px solid; */
  text-align: center;
  width: 70%;
  padding-inline: 5%;
}
.hero section article h1 {
  font-family: Six;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
}
.hero section article p {
  font-family: Protest reg;
  font-size: 30px;
}
.hero section article strong {
  font-family: Six;
  text-align: center !important;
  font-size: 25px;
  line-height: 40px;
}
.hero section article span {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-family: Protest reg;
}
.hero section article span a {
  text-decoration: none;
  color: inherit;
  /* border: 1px solid; */
  padding: 10px;
  /* background-color: blue ;
  color: white; */
}
.hero section article span a:nth-child(2){
  /* background-color: #6CAA69; */
  border: 1px solid #06B8D5;
  border-radius: 5px;
  font-family: Six;
  padding: 10px 40px;
  font-weight: 900;
  font-size: 30px;
}
.features{
  padding-inline: 10%;
}
.features section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.features section h1 {
  font-family: Six;
  font-size: 40px;
}
.features section article {
  display: flex;
  flex-direction: row-reverse;
  gap: 10%;
  font-family: Protest reg;
  align-items: center;
  line-height: 40px;
  font-size: 28px;
  font-weight: 900;
}
.features section article main:nth-child(1) {
  width: 30%;
}
.features section article main:nth-child(1) img{
  width: 100%;
}
.features section article main:nth-child(2) {
  width: 60%;
}
.tweet{
  padding-inline: 10%;
}
.tweet section{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.tweet section img{
  width:60%; 
}
.token{
  padding-inline: 10%;
}
.token section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
  /* border: 1px solid; */
}
.token section h1 {
  font-family: Six;
  font-size: 35px;
}
.token section h4 {
  font-family: Protest reg;
  font-size: 25px;
}
.token section article{
  display: flex;
  align-items: center;
  gap: 10rem;
  /* border: 1px solid; */
  width: 100%;
  padding-block: 6rem;
}
.token section article ul {
  list-style: none;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 5%;
  width: 40%;
}
.token section article main{
  width: 60%;
  /* visibility: hidden; */
}
.token section article main img{
  width: 110%; 
}

.token section ul li {
  width: 50%;
  border: 1px solid;
  margin-bottom: 2rem;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  font-size: 30px;
  font-family: Six;
}
.comm{
  padding-inline: 10%;
}
.comm section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.comm section h1 {
  font-size: 50px;
  font-family: Six;
  text-align: center;
}
.comm section article {
  /* height: 80vh; */
  /* border: 1px solid; */
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.comm section article img{
  width: 33.3%;
  width: 50%;
  /* height: 50%; */
}
.comm section article ul{
  display: flex;
  list-style: none;
  border: 1px red;
  align-items: center;
  justify-content: center;
}
.comm section article ul li{
  width: 100%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comm section article ul li img{
  width: 50%;
  height: 50%;
}
footer {
  display: flex;
  flex-direction: column;
  font-family: Six;
  align-items: center;
  padding-bottom: 5rem;
}
footer .flogo{
  width: 10%;
}
footer h1 {
  font-size: 55px;
  padding-top: 1rem;
}
footer span {
  display: flex;
  gap: 1rem;
}
footer strong {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: Protest reg;
  font-size: 30px;
}
footer strong a {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 769px) and (max-width: 1099px) {
  .nav nav ul {
    font-size: 20px;
  }
  .hero section {
    flex-direction: column;
  }
  .hero section main {
    width: 100%;
  }
  .hero section article {
    text-align: center;
    width: 100%;
  }
  .hero section article strong{
    font-size: 22px;
  }
  .features section article {
    flex-direction: column;
  }
  .features section article main:nth-child(1) {
    width: 100%;
  }
  .features section article main:nth-child(2) {
    width: 100%;
    text-align: justify;
    font-size: 24px;
  }
  footer h1 {
    font-size: 30px;
  }
  footer strong {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .nav nav {
    flex-direction: column;
    gap: 1.5rem;
  }
  .nav nav img{
    width: 50%;
    height: fit-content;
    margin-top: 1rem;
  }
  .nav nav ul {
    font-size: 18px;
    gap: 1rem;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .hero section {
    /* height: fit-content;  */
    /* border: 1px solid; */
    /* margin-top: -6rem; */
    margin-bottom: 4rem;
    gap: 1%;
    flex-direction: column;
    /* background-position: 50px 20%;
    background-size: 50%; */
    /* border: 1px solid; */
  }
  .hero section main img {
    width: 50%;
  }
  .hero section main {
    width: 100%;
  }
  .hero section article {
    text-align: center;
    width: 100%;
    /* transform: translateY(-140%); */
  }
  .hero section article h1 {
    font-size: 35px;
  line-height: 40px;

  }
  .hero section article p {
    font-size: 30px;
  }
  .hero section article strong{
    font-size: 30px;
  }
  .features section {
    align-items: center;
  }
  .features section h1 {
    font-size: 25px;
    line-height: 40px;
    text-align: center;
  }
  .features section article {
    flex-direction: column;
    align-items: center;
  }
  .features section article main:nth-child(1) {
    width: 100%;
  }
  .features section article main:nth-child(2) {
    width: 100%;
    text-align: justify;
    font-size: 22px;
  }
  .tweet{
    padding-inline: 10%;
  }
  .tweet section img{
    width:100%; 
  }
  .token section article{
    padding-block: 2rem;
  }
  .token section h1 {
    font-size: 30px;
  }
  .token section h4{
    font-size: 40px;
  }
  .token section article{
    flex-direction: column;
    gap: 1rem;
    margin-left: -1rem;
  }
  .token section article ul {
    list-style: none;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 5%;
    width: 100%;
    flex-wrap: wrap;
  }
  .token section article main{
    width: 100%;
  }
  .token section ul li {
    width: 100%;
    /* margin-left: -2rem; */
  }
  .comm section article img{
    width: 50%;
    /* height: fit-content; */
  }
  footer h1 {
    font-size: 25px;
  }
  footer strong {
    font-size: 22px;
  }
}


/* Define custom animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); /* Move 20px down */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
  }
}

/* Apply animations to elements */
.nav nav, .hero, .features, .tweet, .token, .comm, footer {
  animation: fadeInUp 1s ease-out;
}

/* Add transitions for smoother effects */
.nav nav img, .tweet section img {
  transition: width 0.5s ease-out; /* Example transition */
}

/* Add hover effects */
.nav nav img:hover, .tweet section img:hover {
  transform: scale(1.1); /* Scale up on hover */
}
