.app{
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  margin-top: -2rem;
  /* padding-left: 10%;
  padding-right: 10%; */
  overflow-x: hidden;
}
@media (min-width: 1100px) {
  .app{
    width: 100%;
    /* background-image: url(/public/images/sid.webp); */
    background-image: linear-gradient(to bottom, #CCEDE7, #CCEDE7, #06B8D5);
    background-repeat: no-repeat;
    overflow-x: hidden;
    background-size: contain;
  }
}
@media (min-width: 769px) and (max-width: 1099px){
  .app{
    width: 90%;
  }
}
@media (max-width: 768px) {
  .app{
    width: 100%;
    gap: 5rem;
    background-image: linear-gradient(to bottom, #CCEDE7, #CCEDE7, #06B8D5);
    background-repeat: no-repeat;
    overflow-x: hidden;
    background-size: contain;
  }
}